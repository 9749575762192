import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./Dashboard.css";
import { TimelineCard } from "../../components/timelineCard/TimelineCard1";
import { TimelineCardPlaceholder } from "../../components/timelineCardPlaceholder/TimelineCardPlaceholder1";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "../../components/styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SET_ACTIVE_TIMELINE } from "../../redux/actions/types";
import { ROUTES } from "../../routes/routes-const";
import { PARAMETERS } from "../../constants/parameters";
import { getUserTimelines } from "../../redux/actions/timelineActions";

interface myProps {}

export const Dashboard: React.FC<myProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const url = new URL(window.location.href);
  const usePng = url.searchParams.get("png");

  const timelines = useSelector((state) => state.timeline.userTimelines);
  useEffect(() => {
    timelines.length === 0 && dispatch(getUserTimelines());
  }, []);

  let filteredTimelines = timelines.filter((t) =>
    t.title.toLowerCase().includes(search.toLowerCase())
  );

  if (usePng) {
    filteredTimelines = filteredTimelines.map((t) => ({
      ...t,
      image: t.image
        .replace("/images/", "/png-images/")
        .replace(".jpg", ".png"),
    }));
  }

  const editTimeline = (id) => {
    dispatch({ type: SET_ACTIVE_TIMELINE, payload: id });
    const url = new URL(window.location.href);
    url.pathname = ROUTES.EDIT_TIMELINE;
    url.searchParams.set(PARAMETERS.ID, id);
    history.push({ pathname: url.pathname, search: url.search });
  };

  const addTimeline = () => {
    const url = new URL(window.location.href);
    url.pathname = ROUTES.ADD_TIMELINE;
    history.push({ pathname: url.pathname, search: url.search });
  };

  return (
    <div className="dashbord-timelines">
      <Button onClick={addTimeline} style={{ width: "120px" }}>
        Add timeline
      </Button>
      <div>
        <Input
          placeholder="Search..."
          className="searchbar-timelines"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="dashbord-timelines-list">
        {filteredTimelines.length > 0
          ? filteredTimelines.map((timeline) => (
              <div className="dashbord-timelines-row" key={timeline._id}>
                <TimelineCard
                  timeline={timeline}
                  key={timeline._id}
                ></TimelineCard>

                <div className="dashbord-timelines-info">
                  <div className="title">
                    Title: {timeline.title.toUpperCase()}
                  </div>
                  <div className="date">
                    Created date:{" "}
                    {dayjs(timeline.createdAt).format("DD/MM/YYYY")}
                  </div>
                  <div className="events-number">
                    Number of events: {timeline.events.length}
                  </div>
                  <Button
                    type="primary"
                    onClick={() => editTimeline(timeline._id)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ))
          : Array.from(Array(8).keys()).map((index) => (
              <TimelineCardPlaceholder
                key={"card-" + index}
              ></TimelineCardPlaceholder>
            ))}
      </div>
    </div>
  );
};
