import React, { useState } from "react";
import "./UserMenu.css";
import {
  DashboardOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";
import { AuthModal } from "../modals/authModal/AuthModal";
import { NavButton } from "../navButton/NavButton";
import { ROUTES } from "../../routes/routes-const";
import { useSelector } from "react-redux";

interface UserMenuProps {}

export const UserMenu: React.FC<UserMenuProps> = ({}) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [isVisible, setIsVisible] = useState(false)

  const content = (
    <div className="user-menu-items">
      {isAuthenticated && (
        <>
          <div className="user-menu-item">
            <DashboardOutlined />
            <NavButton type="secondary" href={ROUTES.DASHBOARD} onClick={()=>setIsVisible(false)}>
              Dashboard
            </NavButton>
          </div>
          <div className="user-menu-item" onClick={()=>setIsVisible(false)}>
            <UserOutlined />
            <NavButton type="secondary" href={ROUTES.PROFILE}>
              Profile
            </NavButton>
          </div>
        </>
      )}
      <div className="user-menu-item">
        {isAuthenticated ? <LogoutOutlined /> : <LoginOutlined />}
        <AuthModal />
      </div>
    </div>
  );

  return (
    <div className="user-menu">
      <Popover
        content={content}
        trigger={"click"}
        // visible={isVisible}
        overlayClassName="user-menu-popover"
      >
        <div className="user-menu-icon"onClick={()=>{setIsVisible(prev=>!prev)}}>
          <UserOutlined />
        </div>
      </Popover>
    </div>
  );
};
