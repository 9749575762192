import { useEffect } from 'react';
const ImportLink = resourceUrl => {
  useEffect(() => {
    if (!document.getElementById(resourceUrl)) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = resourceUrl;
    link.id = resourceUrl;
    link.type = "text/css"
    document.body.appendChild(link);
    }
    // return () => {
    //   document.body.removeChild(link);
    // }
  }, [resourceUrl]);
};
export default ImportLink;