function sanitizeString(input) {
  const entityToChar = {
    "&nbsp;": " ",
    "&amp;": "&",
    "&quot;": '"',
    "&lt;": "<",
    "&gt;": ">",
    // Add more HTML entities here as needed
  };

  return input.replace(/&[^\s;]+;/g, (entity) => entityToChar[entity] || "");
}

function extractWordsOutsideTags(htmlContent) {
  const regex = />([^<]+)</g;
  let wordsArray = [];
  let match;

  while ((match = regex.exec(htmlContent)) !== null) {
    let words = match[1].trim().match(/\b\w+\b/g);
    if (words) {
      wordsArray.push(...words);
    }
  }

  return wordsArray;
}

function selectWordIndexByPercentage(words, percentage) {
  const totalLength = words.reduce((total, word) => total + word.length, 0);
  const targetPosition = percentage * totalLength;
  let cumulativeLength = 0;
  for (let i = 0; i < words.length; i++) {
    cumulativeLength += words[i].length;
    if (cumulativeLength >= targetPosition) {
      return i;
    }
  }
  return words.length - 1;
}

export const wrapWordInBold = (text, charIndex, charLength, progress) => {
  if (progress) {
    return wrapWordInBoldProgress(text, progress);
  } else {
    return wrapWordInBoldChar(text, charIndex, charLength);
  }
};

export const wrapWordInBoldProgress = (textContent, percentage) => {
  textContent = sanitizeString(textContent);
  const words = extractWordsOutsideTags(textContent);
  const wordIndex = selectWordIndexByPercentage(words, percentage);
  const safeIndex = Math.max(0, Math.min(words.length - 1, wordIndex));
  const targetWord = words[safeIndex];
  let currentWordCount = 0;
  let replaced = false;
  const modifiedText = textContent.replace(/\b(\w+)\b/g, (match, word) => {
    if (!replaced && word === targetWord && currentWordCount >= safeIndex) {
      currentWordCount++;
      replaced = true;
      return `<span class="highlighted-word">${word}</span>`;
    } else {
      currentWordCount++;
      return word;
    }
  });

  return modifiedText;
};

export const wrapWordInBoldChar=(text, charIndex, charLength) =>{
  // Ensure charIndex and charLength are within the bounds of the text
  if (charIndex < 0 || charLength <= 0 || charIndex >= text.length) {
    return text;
  }

  // Calculate the end index of the word
  const endIndex = charIndex + charLength;

  // Ensure the end index does not exceed the text length
  if (endIndex > text.length) {
    return text;
  }

  // Extract parts of the string
  const before = text.substring(0, charIndex);
  const word = text.substring(charIndex, endIndex);
  const after = text.substring(endIndex);

  // Wrap the word in bold tags
  const wrappedWord = `<span class="highlighted-word">${word}</span>`;

  // Combine and return the new string
  return before + wrappedWord + after;
}
