import React, { useEffect, useMemo } from "react";
import "./FullDescription.css";
import { AudioPlayProgress, Description } from "../description/Description";
import { HistoryGuesserPlay } from "../history-guesser-play/HistoryGuesserPlay";
import { AffiliateAd } from "../ads/affiliateAd/AffiliateAd";
import { useScreenSize } from "../useScreenSize/useScreenSize";

interface myParams {
  timeline: any;
  selectedEvent: { id: string };
  onPlayAudio?: (event) => void;
  audioPlayProgress: AudioPlayProgress
}

export const FullDescription: React.FC<myParams> = ({
  timeline,
  selectedEvent,
  onPlayAudio,
  audioPlayProgress
}) => {
  const events = useMemo(
    () =>
      timeline?.events?.sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      ) || [],
    [timeline]
  );

  const screenSize = useScreenSize();
  const isMobile = screenSize < 768;

  useEffect(() => {
    if (selectedEvent.id) {
      const element = document.getElementById("event-" + selectedEvent.id);
      const timeglider = document.getElementById("placement");
      if (!element) return;

      var elementPosition = element.getBoundingClientRect().top;

      var offsetPosition =
        elementPosition +
        window.scrollY -
        (timeglider?.getBoundingClientRect()
          ? timeglider?.getBoundingClientRect().height + 60
          : 560);

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [selectedEvent]);

  const getAdForIndex = (i, events) => {
    if (i === 0) return null;
    if (i % 10 === 0 || i === events.length - 1) return <HistoryGuesserPlay />;
    else if (i % 6 === 0) return <AffiliateAd width={300} height={250} />;
    else if (i % 3 === 0)
      return isMobile ? (
        <AffiliateAd width={250} height={250} />
      ) : (
        <AffiliateAd width={728} height={90} />
      );
  };

  return (
    <div className="full-description-wrapper">
      <Description onPlayAudio={onPlayAudio} audioPlayProgress={audioPlayProgress}/>
      {events.map((event, i) => (
        <div key={event._id}>
          <div id={"event-" + event._id}>
            <Description event={event} onPlayAudio={onPlayAudio} audioPlayProgress={audioPlayProgress}/>
          </div>
          <div className="inline-ad-wrapper">{getAdForIndex(i, events)}</div>
        </div>
      ))}
    </div>
  );
};
