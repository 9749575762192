import { postData, fetchData, putData, deleteData } from "../../utils/api";
import { LogService } from "../../utils/logService";
import { authToken } from "./authActions";
import {
  ADD_EVENT,
  ADD_TIMELINE,
  ADD_TIMELINES,
  ADD_USER_TIMELINES,
  DELETE_EVENT,
  EDIT_EVENT,
  SET_DISPLAYED_TIMELINE,
  SET_GUESS_TIMELINE,
  UPDATE_TIMELINE,
} from "./types";

export const getTimelines = () => (dispatch, getState) => {
  fetchData("/api/timelines", authToken(getState)).then(async (items) => {
    LogService.log(items);
    dispatch({
      type: ADD_TIMELINES,
      payload: items,
    });
  });
};

export const getAllTimelines = () => (dispatch, getState) => {
  fetchData("/api/timelines/all", authToken(getState)).then(async (items) => {
    LogService.log(items);
    dispatch({
      type: ADD_TIMELINES,
      payload: items,
    });
  });
};

export const getUserTimelines = () => (dispatch, getState) => {
  fetchData("/api/timelines/user", authToken(getState)).then(async (items) => {
    LogService.log("UserTimelines", items);
    dispatch({
      type: ADD_USER_TIMELINES,
      payload: items,
    });
  });
};

export const getTimeline = (id) => (dispatch, getState) => {
  fetchData("/api/timelines/" + id, authToken(getState)).then(async (item) => {
    LogService.log("Timeline", item);
    dispatch({
      type: SET_DISPLAYED_TIMELINE,
      payload: item,
    });
    return item;
  }).catch(()=>
    dispatch({
      type: SET_DISPLAYED_TIMELINE,
      payload: null,
    })
  );
};

export const getGuessTimeline = () => (dispatch) => {
  fetchData("/api/timelines/guess/656d8c30fcc815caf56e7c70").then(
    async (item) => {
      LogService.log(item);
      dispatch({
        type: SET_GUESS_TIMELINE,
        payload: item,
      });
    }
  );
};

export const createTimeline =
  ({ userId, data }) =>
  (dispatch, getState) => {
    postData("/api/timelines", data, authToken(getState)).then((item) => {
      dispatch({
        type: ADD_TIMELINE,
        payload: item,
      });
    });
  };

export const updateTimeline =
  ({ userId, data }) =>
  async (dispatch, getState) => {
    putData("/api/timelines", data, authToken(getState)).then((item) => {
      dispatch({
        type: UPDATE_TIMELINE,
        payload: item,
      });
    });
  };

export const createEvent = (data) => (dispatch, getState) => {
  postData("/api/events", data, authToken(getState)).then((item) => {
    if (item.success) {
      dispatch({
        type: ADD_EVENT,
        payload: data.data,
      });
    }
  });
};

export const editEvent = (data) => (dispatch, getState) => {
  putData("/api/events", data, authToken(getState)).then((item) => {
    if (item.success) {
      dispatch({
        type: EDIT_EVENT,
        payload: data.data,
      });
    }
  });
};

export const deleteEvent = (data) => (dispatch, getState) => {
  deleteData("/api/events", data, authToken(getState)).then((item) => {
    if (item.success) {
      dispatch({
        type: DELETE_EVENT,
        payload: data.data,
      });
    }
  });
};
