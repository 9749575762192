import React from "react";
import "./TimelinePlaceholder.css";

export const TimelinePlaceholder: React.FC = () => {
  return (
    <div className="timeline-placeholder">
      <div className="timeline-placeholder-header"></div>
      <div className="timeline-placeholder-content">
        <div style={{ width: "30%" }} />
        <div style={{ width: "20%" }} />
        <div style={{ width: "35%" }} />
        <div style={{ width: "50%" }} />
        <div style={{ width: "15%" }} />
        <div style={{ width: "20%" }} />
        <div style={{ width: "25%" }} />
        <div style={{ width: "20%" }} />
        <div style={{ width: "35%" }} />
      </div>
      <div className="timeline-placeholder-footer"></div>
    </div>
  );
};
