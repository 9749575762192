import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import "./NavButton.css";
import React from "react";
interface NavButtonProps {
  href: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: string
}

export const NavButton: React.FC<NavButtonProps> = ({
  children,
  onClick,
  className,
  href,
  type = 'primary'
}) => {
  return href ? (
    <NavLink to={href} className="nav-button-link">
      <span
        className={`nav-button${className ? " " + className : ""} nav-button-${type}`}
        onClick={onClick}
      >
        {children}
      </span>
    </NavLink>
  ) : (
    <span
      className={`nav-button${className ? " " + className : ""} nav-button-${type}`}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
