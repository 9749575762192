import "./RegisterForm.css";

import { Form, Field } from "react-final-form";
import { Button, Input, Label } from "../../styled-components";
import {
  composeValidators,
  emailVerification,
  passwordVerification,
  required,
  usernameLimit,
} from "../../../utils/formValidators";
import { CustomButton } from "../../customButton/CustomButton";

export const RegisterForm = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={(data) => {
        onSubmit(data);
      }}
    >
      {({ handleSubmit }) => (
        <form className="add-event-form" onSubmit={handleSubmit}>
          <div className="form-content">
            <div className="left">
              <Label>Username</Label>
              <Field
                name="username"
                validate={composeValidators(required, usernameLimit)}
              >
                {({ input, meta }) => (
                  <div>
                    <Input
                      className="form-input"
                      placeholder="username"
                      type="text"
                      {...input}
                    />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>

              <Label>Email</Label>
              <Field
                name="email"
                validate={composeValidators(required, emailVerification)}
              >
                {({ input, meta }) => (
                  <div>
                    <Input
                      className="form-input"
                      placeholder="email@email.com"
                      type="text"
                      {...input}
                    />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>

              <Label>Password</Label>
              <Field
                name="password"
                validate={composeValidators(required, passwordVerification)}
              >
                {({ input, meta }) => (
                  <div>
                    <Input
                      className="form-input"
                      placeholder="password"
                      type="password"
                      {...input}
                    />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </div>

          <CustomButton className="form-input register-button" type="primary">
            Register
          </CustomButton>
        </form>
      )}
    </Form>
  );
};
