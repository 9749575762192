import "./Disclaimer.css";

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <div className="disclaimer-info">
        <p className="disclaimer-text"> <strong>TimeTrail Disclaimer </strong></p>
        <p className="disclaimer-text"> Last Updated: 29/01/2024 </p>
        <p className="disclaimer-text">
          <strong>Welcome to TimeTrail!</strong>
        </p>

        <p className="disclaimer-text">
          At TimeTrail, we blend the precision of technology with human creativity to offer interactive timelines that are both engaging and informative. Our content is a unique mixture of AI-generated material and human-curated historical data. This combination allows us to provide a distinctive perspective on history. However, we also recognize the importance of transparency and accuracy in our offerings. Therefore, we present this disclaimer.
        </p>
        <p className="disclaimer-text">
          <span className="disclaimer-title">1. Nature of Content</span>

          Combination of AI-Generated and Human-Curated Content: TimeTrail's content includes a mix of AI-generated text and images alongside data and information curated by our team of historians and researchers. While we strive for accuracy and relevance, please note that AI-generated content may not always align perfectly with historical facts.
          Historical Data: Our human-curated content is informed by credible historical data and resources. We endeavor for accuracy but cannot guarantee the infallibility of these sources.
        </p>

        <p className="disclaimer-text">
          <span className="disclaimer-title">
            2. Purpose of the Site
          </span>

          TimeTrail is intended for educational and entertainment purposes. It should not be considered an exhaustive or authoritative source of historical information.
        </p>

        <p className="disclaimer-text">
          <span className="disclaimer-title">
            3. Intellectual Property
          </span>

          The unique combination of AI-generated content and human-curated data on TimeTrail represents a creative endeavor. We claim no ownership over individual historical facts but assert rights over the distinctive way these elements are combined and presented on our platform.
        </p>
        <p className="disclaimer-text">
          <span className="disclaimer-title">
            4. User Responsibility
          </span>

          We encourage users to use TimeTrail as a starting point for learning and to conduct independent research for scholarly or professional purposes.
        </p>
        <p className="disclaimer-text">

          <span className="disclaimer-title">
            5. External Links
          </span>

          TimeTrail may include links to external websites. These links are provided for additional information, but we are not responsible for the content or accuracy of these external sites.
        </p>

        <p className="disclaimer-text">
          <span className="disclaimer-title">
            6. Content Changes and Updates
          </span>

          The content on TimeTrail is dynamic and may be updated regularly to reflect new information, improve the user experience, or for other purposes. These changes may occur without notice.
        </p>

        <p className="disclaimer-text">
          <span className="disclaimer-title">
            7. No Warranty
          </span>

          TimeTrail's content is provided “as is” without any representations or warranties, express or implied. We do not warrant that the information will be constantly available, accurate, complete, or non-misleading.
        </p>
        <p className="disclaimer-text">
        </p>
        <p className="disclaimer-text">
          For any question, please contact us at{" "}
          <strong>contact.timetrail@gmail.com</strong>
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;