export const shuffleArray = (array) => {
  const arrayCopy = [...array];
  let currentIndex = arrayCopy.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [arrayCopy[currentIndex], arrayCopy[randomIndex]] = [
      arrayCopy[randomIndex],
      arrayCopy[currentIndex],
    ];
  }

  return arrayCopy;
};

export const secondsToScore = (intervalInSeconds) => {
  // Define the key points for our piecewise linear function
  const points = [
    { time: 86400, score: 1000 }, // 1 day
    { time: 31536000, score: 800 }, // 1 year
    { time: 157680000, score: 500 }, // 5 years
    { time: 3153600000, score: 0 }, // 100 years
  ];

  // If the interval is less than a day, return perfect score
  if (intervalInSeconds <= points[0].time) {
    return points[0].score;
  }

  // If the interval is greater than 100 years, return 0
  if (intervalInSeconds >= points[points.length - 1].time) {
    return points[points.length - 1].score;
  }

  // Find the two points between which the interval falls and interpolate the score
  for (let i = 0; i < points.length - 1; i++) {
    if (intervalInSeconds <= points[i + 1].time) {
      const timeDiff = points[i + 1].time - points[i].time;
      const scoreDiff = points[i + 1].score - points[i].score;
      const timeFraction = (intervalInSeconds - points[i].time) / timeDiff;
      return points[i].score + timeFraction * scoreDiff;
    }
  }

  return 0;
};

export const msToIsoNoTimezone = (miliseconds) => {
  const tzOffset = new Date().getTimezoneOffset() * 60000;
  const fixedDateObj = new Date(miliseconds - tzOffset);
  const isoString = fixedDateObj.toISOString();
  let [date, time] = isoString.split("T");
  // There are 15 extra minutes added. Removing them by force
  const fixedDateString = [date, "00:00:00.000Z"].join("T");
  return fixedDateString;
};

function getKeyMapFromText(text) {
  const lines = text.split(/\r?\n/); // Split content into lines, handling both Windows and Unix line endings
  const keyValueMap = lines.reduce((acc, line) => {
    const [key, value] = line.split("=");
    if (key && value) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return keyValueMap;
}

export function getValueByKey(text, key) {
  const keyValueMap = getKeyMapFromText(text);
  return keyValueMap[key];
}
