import "./Home.css";
import { useSelector } from "react-redux";
import { CategoryList } from "../../components/categoryList/CategoryList";
import { useHistory } from "react-router";
import { ROUTES } from "../../routes/routes-const";
import { CustomButton } from "../../components/customButton/CustomButton";
import { CategoryListNew } from "../../components/categoryListNew/CategoryListNew";
import {useScreenSize} from "../../components/useScreenSize/useScreenSize";
import { useRef } from "react";
import { HistoryGuesserPlay } from "../../components/history-guesser-play/HistoryGuesserPlay";

function Home() {
  const timelines = useSelector((state) => state.timeline.timelines);
  const myRef = useRef(null)

  const screenSize = useScreenSize()
  const isMobile = screenSize < 768;

  const history = useHistory();

  const goToGame = () => {
    const url = new URL(window.location.href);
    url.pathname = ROUTES.GUESSER;
    history.push({ pathname: url.pathname });
  }

  const onExplore = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="home">
      <div className="home-info-background">
        <div className="home-info-content">
          <div className="home-info-text home-info-explore">
            <div className="home-info-explore-content">
              <span>Explore historical timelines</span>
              <CustomButton type={'primary'} size={isMobile ? 'medium' : 'large'} onClick={onExplore}>Explore</CustomButton>
            </div>
          </div>
          <div className="home-info-text home-info-play">
            <div className="home-info-play-content">
              <span>Test your historical knowledge</span>
              <CustomButton type={'secondary'} size={isMobile ? 'medium' : 'large'} onClick={goToGame}>Play</CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="home-content" ref={myRef}>
        <CategoryListNew name={"New content"} timelines={timelines.slice(0, 3)} />
        <CategoryList name={"Library"} timelines={timelines.slice(3)} />
        <HistoryGuesserPlay />
      </div>
    </div>
  );
}

export default Home;
