const fullDates =
  /\b\d{1,4}[-/]\d{1,2}[-/]\d{1,4}\b|\b\d{1,2}[-/]\d{1,2}[-/]\d{2,4}\b|\b\d{1,4}[-.]\d{1,2}[-.]\d{1,4}\b|\b\d{1,2}[-.]\d{1,2}[-.]\d{2,4}\b|\b\d{1,2}\/\d{1,2}\/\d{2,4}\b|\b\d{1,2}\s[a-zA-Z]{3,}\s\d{2,4}\b|\b[a-zA-Z]{3,}\s\d{1,2},?\s\d{2,4}\b/g;
const yearsAcBc = /\b\d{1,4}\s*(?:AD|CE|BC|BCE)\b/g;
const monthYear =
  /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,}\b/g;
const century = /\b\d{1,2}(?:st|nd|rd|th)\s+century\b/g;
const months = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\b/g;
const inYear = /\b(?:in\s+)?\d{3,4}\b/g;

const ytFigure =/<figure.*?>.*?<\/figure>/g;
const ytPlaceholder="________YTFIGURE________"

export const hideDates = (text) => {
  const ytFigureCache = text.match(ytFigure);

  return text
    .replaceAll(ytFigure, ytPlaceholder)
    .replaceAll(fullDates, " ***** ")
    .replaceAll(monthYear, " ***** ")
    .replaceAll(yearsAcBc, " ***** ")
    .replaceAll(century, " ***** ")
    .replaceAll(months, " ***** ")
    .replaceAll(inYear, "in ***** ")
    .replaceAll(ytPlaceholder, ytFigureCache)
};
