import { Spin } from "antd";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

export const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Route
      {...rest}
      render={() => {
        if(isAuthenticated === null){
          return <Spin />
        }

        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        );
      }}
    />
  );
};
