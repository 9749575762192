import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "../components/privateRoute/PrivateRoute";
import Home from "../pages/home/Home";
import Timeline from "../pages/timeline/Timeline";
import HistoryGuesser from "../pages/historyGuesser/HistoryGuesser";
import { ROUTES } from "./routes-const";
import { Suspense, lazy } from "react";
import Disclaimer from "../pages/disclaimer/Disclaimer";
import About from "../pages/about/About";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import TermsServices from "../pages/termsServices/TermsServices";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Profile } from "../pages/profile/Profile";

const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const AddTimeline = lazy(() => import("../pages/addTimeline/AddTimeline"));
const EditTimeline = lazy(() => import("../pages/editTimeline/EditTimeline"));

export const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME}>
        <Home />
      </Route>

      <Route path={ROUTES.TIMELINE}>
        <Timeline />
      </Route>

      <Route path={ROUTES.GUESSER}>
        <HistoryGuesser />
      </Route>

      <Route path={ROUTES.ABOUT}>
        <About />
      </Route>

      <Route path={ROUTES.DISCLAIMER}>
        <Disclaimer />
      </Route>

      <Route path={ROUTES.PRIVACY}>
        <PrivacyPolicy />
      </Route>

      <Route path={ROUTES.TERMS}>
        <TermsServices />
      </Route>

      <PrivateRoute path={ROUTES.ADD_TIMELINE}>
        <Suspense fallback={<div>Loading</div>}>
          <AddTimeline />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute path={ROUTES.EDIT_TIMELINE}>
        <Suspense fallback={<div>Loading</div>}>
          <EditTimeline />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute path={ROUTES.DASHBOARD}>
        <Suspense fallback={<div>Loading</div>}>
          <Dashboard />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute path={ROUTES.PROFILE}>
        <Suspense fallback={<div>Loading</div>}>
          <Profile />
        </Suspense>
      </PrivateRoute>

      <Route>
        <Suspense fallback={<div>Loading</div>}>
          <NotFound />
        </Suspense>
      </Route>
    </Switch>
  );
};
