import {
  ADD_EVENT,
  ADD_TIMELINE,
  ADD_TIMELINES,
  ADD_USER_TIMELINES,
  DELETE_EVENT,
  DELETE_TIMELINE,
  EDIT_EVENT,
  SET_ACTIVE_TIMELINE,
  SET_DISPLAYED_TIMELINE,
  SET_GUESS_TIMELINE,
  UPDATE_TIMELINE,
} from "../actions/types";

const initialState = {
  activeTimeline: {},
  guessTimeline: undefined,
  displayedTimeline: undefined,
  timelines: [],
  userTimelines: [],
};

export const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TIMELINE:
      return {
        ...state,
        timelines: [...state.timelines, action.payload],
        activeTimeline: action.payload,
      };
    case ADD_TIMELINES:
      return {
        ...state,
        timelines: [...action.payload],
      };
    case UPDATE_TIMELINE:
      return {
        ...state,
        activeTimeline: action.payload,
        userTimelines: state.userTimelines.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case DELETE_TIMELINE:
      return {
        ...state,
        userTimelines: state.userTimelines.filter(
          (item) => item._id !== action.payload
        ),
      };
    case ADD_USER_TIMELINES:
      return {
        ...state,
        userTimelines: [...action.payload],
      };
    case ADD_EVENT: {
      const newTimeline = {
        ...state.activeTimeline,
        events: [...state.activeTimeline.events, action.payload],
      };

      const newTimelines = state.userTimelines.map((item) =>
        item._id === newTimeline._id ? newTimeline : item
      );

      return {
        ...state,
        userTimelines: newTimelines,
        activeTimeline: newTimelines.find(
          (item) => item._id === newTimeline._id
        ),
      };
    }
    case EDIT_EVENT: {
      const newTimeline = {
        ...state.activeTimeline,
        events: state.activeTimeline.events.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };

      const newTimelines = state.userTimelines.map((item) =>
        item._id === newTimeline._id ? newTimeline : item
      );

      return {
        ...state,
        userTimelines: newTimelines,
        activeTimeline: newTimelines.find(
          (item) => item._id === newTimeline._id
        ),
      };
    }
    case DELETE_EVENT: {
      const newTimeline = {
        ...state.activeTimeline,
        events: state.activeTimeline.events.filter(
          (item) => item._id !== action.payload._id
        ),
      };

      const newTimelines = state.userTimelines.map((item) =>
        item._id === newTimeline._id ? newTimeline : item
      );

      return {
        ...state,
        userTimelines: newTimelines,
        activeTimeline: newTimelines.find(
          (item) => item._id === newTimeline._id
        ),
      };
    }
    case SET_ACTIVE_TIMELINE:
      return {
        ...state,
        activeTimeline: state.userTimelines.find(
          (item) => item._id === action.payload
        ),
      };
    case SET_DISPLAYED_TIMELINE:
      return {
        ...state,
        displayedTimeline: action.payload,
      };
    case SET_GUESS_TIMELINE:
      return {
        ...state,
        guessTimeline: action.payload,
      };
    default:
      return state;
  }
};
