import "./TimelineCard1.css";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Fragment } from "react";
// import { PARAMETERS } from "../../constants/parameters";
import { generateTimelineLink } from "../../utils/generateTimelineLink";
// import { ROUTES } from "../../routes/routes-const";
// import { getTimeline } from "../../redux/actions/timelineActions";

export const TimelineCard = ({ timeline }) => {
  // const dispatch = useDispatch();
  // const history = useHistory();

  // const displayTimeline = (id) => {
  //   dispatch(getTimeline(id))
  //   const url = new URL(window.location.href);
  //   url.pathname = ROUTES.TIMELINE;
  //   url.searchParams.set(PARAMETERS.ID, id);
  //   history.push({ pathname: url.pathname, search: url.search });
  // };

  return (
    <Fragment>
      <a
        href={generateTimelineLink(timeline._id)}
        // onClick={(e) => {
        //   e.preventDefault();
        //   return false;
        // }}
        key={timeline._id}
        className="timeline-card-link"
      >
        <div className="timeline-card">
          <img className="timeline-image"
            src={timeline.image}
            onClick={() => displayTimeline(timeline._id)}
            alt={timeline.image
              .split('/').join(' ')
              .split('\\').join(' ')
              .split('-').join(' ')}
          />
          <div className="timeline-info-wrapper">
            <span className="card-timeline-title">{timeline.title}</span>
          </div>
        </div>
      </a>
    </Fragment>
  );
};
