import React from "react";
import "./Description.css";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { PlayCircleOutlined } from "@ant-design/icons";
import { wrapWordInBold } from "./utils";
import { Image } from "antd";

export interface AudioPlayProgress {
  id: string;
  progress?: number;
  charIndex?: number;
  charLength?: number;
}

interface myParams {
  event?: any;
  isGuesser?: boolean;
  showIntroduction?: () => void;
  onPlayAudio?: (event) => void;
  audioPlayProgress: AudioPlayProgress;
}

export const Description: React.FC<myParams> = ({
  event,
  isGuesser,
  showIntroduction,
  onPlayAudio,
  audioPlayProgress,
}) => {
  const displayedTimeline = useSelector(
    (state) => state.timeline.displayedTimeline
  );

  const source = event || displayedTimeline;

  const formatDateWithBC = (date) => {
    const year = dayjs(date).year();
    if (year < 0) {
      // For negative years, convert to BC
      return `${Math.abs(year)} BC`;
    } else {
      // For positive years, just format the year normally
      return year.toString();
    }
  };

  const getStartEndYears = (event) => {
    if (!event) return "";

    if (!event.startDate && !event.endDate) return "";

    let result = "";
    if (event.startDate && event.endDate)
      result =
        formatDateWithBC(event.startDate) +
        "-" +
        formatDateWithBC(event.endDate);
    else if (event.startDate) result = formatDateWithBC(event.startDate);

    if (!result) return "";
    return result;
  };

  const eventTableImage = (source) =>
    source.image
      ? `<div class="description-image-wrapper description-image-wrapper-tablet">
      <img
        alt="Image"
        src="${source.image}"
        class="description-image"
        title="${source.imageAttribution}"
      />
    </div>`
      : ``;

  const isPlayingEvent = source && audioPlayProgress?.id === source._id;

  return (
    <div className="description-wrapper">
      {source && (
        <div className="description">
          <div className="info-text">
            {event && showIntroduction && (
              <span className="back-to-introduction" onClick={showIntroduction}>
                <u>Back to introduction</u>
              </span>
            )}
            {source.title && (
              <h1 className="description-title">
                <div className="title-wrapper">
                  {onPlayAudio && <div className="title-play-audio" />}
                  <span>{source.title}</span>
                  {onPlayAudio && (
                    <div
                      className="title-play-audio"
                      onClick={() => onPlayAudio(source)}
                    >
                      <PlayCircleOutlined />
                    </div>
                  )}
                </div>

                {!isGuesser && (
                  <span className="description-title-date">
                    {getStartEndYears(event)}
                  </span>
                )}
              </h1>
            )}

            <div className="description-content-wrapper">
              <div
                className={`description-text${
                  isPlayingEvent ? " playing-audio" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html:
                    eventTableImage(source) +
                    (isPlayingEvent
                      ? wrapWordInBold(
                          source.description,
                          audioPlayProgress.charIndex,
                          audioPlayProgress.charLength,
                          audioPlayProgress.progress
                        )
                      : source.description),
                }}
              ></div>

              <div className="info-image">
                {source.image && (
                  <div className="description-image-wrapper">
                    {/* <img
                      alt='Image'
                      src={source.image}
                      className='description-image'
                      title={source.imageAttribution}
                    /> */}
                    <Image
                      preview={{minScale:1, maxScale:1, movable:false, toolbarRender:()=><></>}}
                      alt="Image"
                      className="description-image"
                      // wrapperClassName="description-image-wrapper"
                      title={source.imageAttribution}
                      src={source.image}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
