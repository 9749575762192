import { fetchData, postData } from "../../utils/api";
import { returnErrors } from "./errorAction";
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_USER_COUNTRY,
  USER_LOADED,
  USER_LOADING,
} from "./types";

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  fetchData("/api/auth/user", authToken(getState))
    .then((res) =>
      dispatch({
        type: USER_LOADED,
        payload: res,
      })
    )
    .catch((err) => {
      dispatch(returnErrors(err.msg, err.status));
      dispatch({ type: AUTH_ERROR });
    });
};

export const registerUser =
  ({ username, email, password }) =>
  (dispatch) => {
    const body = { username, email, password };
    postData("/api/users", body)
      .then((res) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.msg, err.status, "REGISTER_FAIL"));
        dispatch({ type: REGISTER_FAIL });
      });
  };

export const loginUser =
  ({ email, password }) =>
  (dispatch) => {
    const body = { email, password };
    postData("/api/auth", body)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.msg, err.status, "LOGIN_FAIL"));
        dispatch({ type: LOGIN_FAIL });
      });
  };

export const logoutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

export const setUserCountry = (data) => async (dispatch) => {
    dispatch({
      type: SET_USER_COUNTRY,
      payload: data,
    });
};

export const tokenConfig = (getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    config.headers["x-auth-token"] = token;
  }

  return config;
};

export const authToken = (getState) => {
  const token = getState().auth.token;
  return token;
};
