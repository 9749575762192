import React, { ReactNode } from "react";
import "./CustomButton.css";

interface CustomButtonProps {
  children: ReactNode | string;
  className?: string;
  type?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  onClick: (e) => void;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  size = "medium",
  type = "primary",
  disabled,
  className,
  onClick,
}) => {
  return (
    <button
      className={`custom-button custom-button-${type} custom-button-${size}${className?' '+className:''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="custom-button-content">{children}</div>
    </button>
  );
};
