import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import { NavButton } from "../navButton/NavButton";

export const Logout = () => {
  const dispatch = useDispatch();
  const _logout = () => {
    dispatch(logoutUser());
  };

  return <NavButton type='secondary' onClick={_logout}>Logout</NavButton>;
};
