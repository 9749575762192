import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export const Meta = () => {
  const timelines = useSelector((state) => state.timeline.timelines);
  const displayedTimelineId = useSelector(
    (state) => state.timeline.displayedTimelineId
  );

  const displayedTimeline =
    timelines.find((item) => item._id === displayedTimelineId) || {};

  return (
    <Fragment>
      {displayedTimeline._id ?(
        <Helmet>
          <title>{displayedTimeline.title}</title>
          <meta
            name="description"
            content={displayedTimeline.description.slice(0, 500)}
          />
          <meta property="og:title" content={displayedTimeline.title} />
          <meta
            property="og:description"
            content={displayedTimeline.description.slice(0, 500)}
          />
          <meta property="og:image" content={displayedTimeline.image} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      ):
        <Helmet>
          <meta
            name="description"
            content={`Explore history like never before with TimeTrail – your gateway to immersive, interactive historical timelines. Our platform brings the past to life, making history accessible, engaging, and informative for everyone. Whether you're a student, educator, history enthusiast, or just curious about the past, TimeTrail offers a unique journey through time. Dive into richly detailed timelines, interactive maps, and captivating narratives that illuminate key events, figures, and epochs. From ancient civilizations to modern milestones, experience history in a whole new way with TimeTrail, where every moment matters.`}
          />
          <meta
            property="og:description"
            content={`Explore history like never before with TimeTrail – your gateway to immersive, interactive historical timelines. Our platform brings the past to life, making history accessible, engaging, and informative for everyone. Whether you're a student, educator, history enthusiast, or just curious about the past, TimeTrail offers a unique journey through time. Dive into richly detailed timelines, interactive maps, and captivating narratives that illuminate key events, figures, and epochs. From ancient civilizations to modern milestones, experience history in a whole new way with TimeTrail, where every moment matters.`}
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      }
    </Fragment>
  );
};
