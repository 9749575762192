import "./AuthModal.css";
import { useDispatch, useSelector } from "react-redux";
import { RegisterForm } from "../../forms/registerForm/RegisterForm";
import { LoginForm } from "../../forms/loginForm/LoginForm";
import { loginUser, registerUser } from "../../../redux/actions/authActions";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { NavButton } from "../../navButton/NavButton";
import { CloseButton } from "../../styled-components/CloseButton";
import { InfoCircleOutlined } from '@ant-design/icons'
import { CustomButton } from "../../customButton/CustomButton";
import { Logout } from "../../logout/Logout";

export const AuthModal = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const error = useSelector((state) => state.error);

  const [show, setShow] = useState(false);
  const [login, setLogin] = useState(true);

  const dispatch = useDispatch();

  const _registerUser = (data) => {
    const user = {
      username: data.username,
      email: data.email,
      password: data.password,
    };
    dispatch(registerUser(user));
  };

  const _loginUser = (data) => {
    const user = {
      email: data.email,
      password: data.password,
    };
    dispatch(loginUser(user));
  };

  useEffect(() => {
    if (isAuthenticated) setShow(false);
  }, [isAuthenticated]);

  const customStyle = {
    overlay: { zIndex: 6000, backgroundColor: "rgba(0, 0, 0, 0.3)" },
    content: { height: "500px", width: "400px", margin: "auto" },
  };

  return (
    <div>
      {!isAuthenticated ? <NavButton type="secondary" onClick={() => setShow(!show)}> Login </NavButton> : <Logout />}
      <Modal isOpen={show} style={customStyle}>
        <div className="modal-header">
          <CloseButton onClick={() => setShow(false)} className="close-button" />
          <h2>{login ? "Login" : "Register"}</h2>
        </div>

        {login ? (
          <LoginForm onSubmit={_loginUser}></LoginForm>
        ) : (
          <RegisterForm onSubmit={_registerUser}></RegisterForm>
        )}

        <div className="auth-togle">
          <CustomButton
            type="tertiary"
            className="auth-togle-button"
            onClick={() => setLogin(!login)}
          >
            {login ? "Register" : "Login"}
          </CustomButton>

          {error &&
            (error.id === "REGISTER_FAIL" || error.id === "LOGIN_FAIL") && (
              <span className="error-msg"> {error.msg.msg}</span>
            )}
        </div>

        <div className="register-anouncement">
          {login ?
            <p>
              <InfoCircleOutlined />
              &nbsp;We currently do not support the ability to
              register new accounts, but this is planned for the near future.
            </p>
            : " "}
        </div>
      </Modal>
    </div>
  );
};
