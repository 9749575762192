import React, { useEffect, useState } from "react";
import { Statistic } from "antd";
import "./CountdownTimer.css";

const { Countdown } = Statistic;

interface myParams {
  reset: number;
  running: boolean;
  onFinish: () => void;
}

export const CountdownTimer: React.FC<myParams> = ({ reset, running, onFinish }) => {
  const [deadline, setDeadline] = useState(0);

  useEffect(() => {
    if (running) {
      setDeadline(Date.now() + 60000);
    } else {
      setDeadline(Date.now());
    }
  }, [reset, running]);

  return (
    <div className="countdown-timer">
      <Countdown value={deadline} onFinish={onFinish} format={"mm:ss"} />
    </div>
  );
};
