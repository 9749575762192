export const EMPTY_TIMELINE_STRING = { _id: "id", title: " ", events: [] };
export const GUESS_TIMELINE = {
  _id: "guess-timeline",
  title: "History Guesser",
  initialZoom: 50,
  maxZoom: 65,
  imageLaneHeight: 130,
  focusDate: "2000-10-01",
  backgroundColor: "#ccc",
  events: [
    {
          _id: "left-limit",
          title: "LeftLimit",
          startDate: "-5000-10-01",
          importance: 20,
    },
    {
          _id: "right-limit",
          title: "RightLimit",
          startDate: "5000-10-01",
          importance: 20,
    },
  ],
};

export const AD_REFRESH_INTERVAL = 60000;
export const AD_LOAD_DELAY = 10000;

export const SPAN_COLORS = [
  "#11aea7",
  "#37D67A",
  "#697689",
  "#799DD2",
  "#8f9c80",
  "#9f574f",
  "#BA68C8",
  "#BED3F3",
  "#BEDADC",
  "#C1E1C5",
  "#C4DEF6",
  "#D4C4FB",
  "#D9E3F0",
  "#DCE775",
  "#EB9694",
  "#F47373",
  "#FAD0C3",
  "#FEF3BD",
  "#FF8A65",
  "#cd8b62",
  "#dccc6f",
  "#eed7a1",
  "#f1cd3b",
  "#f7efd2",
];
