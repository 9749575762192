import {
  SET_TIMEGLIDER_VISIBLE,
  SET_TIMEGLIDER_DATA,
  SET_TIMEGLIDER_HEIGHT,
  SET_TIMEGLIDER_STYLE,
} from "../actions/types";

const initialState = {
  visible: false,
  data: {},
  height: "500px",
  style: {},
};

export const timegliderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMEGLIDER_VISIBLE:
      return { ...state, visible: action.payload };
    case SET_TIMEGLIDER_DATA:
      return { ...state, data: action.payload };
    case SET_TIMEGLIDER_HEIGHT:
      return { ...state, height: action.payload };
    case SET_TIMEGLIDER_STYLE:
      return { ...state, style: action.payload };
    default:
      return state;
  }
};
