import React, { useState } from "react";
import { useEffect } from "react";

interface myParams {
  text: string;
  isPlaying: boolean;
  onReadWord: (charIndex: number, charLength: number) => void;
  reset?: number;
}

const synth = window.speechSynthesis;

export const AudioPointer: React.FC<myParams> = ({
  text,
  isPlaying,
  onReadWord,
  reset,
}) => {
  const [resumeInterval, setResumeInterval] = useState<number>();
  const startPlayingText = () => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US";
    const voice = synth
      .getVoices()
      .find((v) => v.name.includes("Microsoft George"));
    if (voice) {
      utterance.voice = voice;
    }
    utterance.volume = 0;

    utterance.onboundary = (event) => {
      if (event.name === "word") {
        onReadWord(event.charIndex, event.charLength);
      }
    };

    synth.cancel();
    synth.speak(utterance);
  };

  useEffect(() => {
    if (!synth) return;
    function voiceChangedListener() {
      const voices = synth.getVoices();
    }
    synth.addEventListener("voiceschanged", voiceChangedListener);
    return synth.removeEventListener("voiceschanged", voiceChangedListener);
  }, []);

  useEffect(() => {
    if (!synth) return;
    if (isPlaying) {
      if (synth.paused) {
        const interval = setTimeout(() => {
          synth.resume();
        }, 1000);

        setResumeInterval(interval);
      }
    } else {
      clearInterval(resumeInterval);
      synth.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!synth) return;
    if (isPlaying && !synth.paused && text) {
      startPlayingText();
    }
  }, [text, isPlaying, reset]);

  return <div></div>;
};
