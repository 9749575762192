import { useEffect } from "react";
const ImportScript = (resourceUrl, persist = true) => {
  useEffect(() => {
    if (!document.getElementById(resourceUrl)) {
      const script = document.createElement("script");
      script.src = resourceUrl;
      script.id = resourceUrl;
      script.async = false;
      document.body.appendChild(script);

      if (!persist) {
        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [resourceUrl]);
};

export default ImportScript;
