import React from "react";
import "./PlayerControls.css";
import {
  CaretRightOutlined,
  PauseOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

interface myProps {
  isPlaying: boolean;
  onStart: () => void;
  onPause: () => void;
  isDisabled?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  style?: React.CSSProperties;
}

export const PlayerControls: React.FC<myProps> = ({
  onStart,
  onPause,
  onPrevious,
  onNext,
  isPlaying,
  isDisabled,
  isPrevDisabled,
  isNextDisabled,
  style = {},
}) => {
  return (
    <div className="player-controls" style={style}>
      {
        <div
          className={
            `player-control-button ${isPrevDisabled ? "disabled" : ""}`
          }
          onClick={onPrevious}
        >
          <StepBackwardOutlined />
        </div>
      }

      {!isPlaying && (
        <div
          className="player-control-button player-play-button"
          onClick={onStart}
          disabled={isDisabled}
        >
          <CaretRightOutlined />
        </div>
      )}

      {isPlaying && (
        <div
          className="player-control-button player-pause-button"
          onClick={onPause}
          disabled={isDisabled}
        >
          <PauseOutlined />
        </div>
      )}
      {
        <div
          className={
            `player-control-button ${isNextDisabled ? "disabled" : ""}`
          }
          onClick={onNext}
        >
          <StepForwardOutlined />
        </div>
      }
    </div>
  );
};
