import styled from "styled-components";

export const Button = styled.button`
  padding: 1rem 1rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.Size.smallSize};
  width: ${(props) => props.sizeComponent};
  height: ${(props) => props.heightComponent};
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 0;
  appearance: none;
  color: ${(props) =>
    props.colorComponent ? props.colorComponent : props.theme.Color.White};
  background: ${(props) => {
    switch (props.background) {
      case "primary":
        return props.theme.Color.Primary;
      case "secondary":
        return props.theme.Color.Secondary;
      case "delete":
        return props.theme.Color.Error;
      default:
        return props.theme.Color.Primary;
    }
  }};

  &:hover {
    transition: 0s;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  }

  &:disabled {
    background: var(--fourth);
    cursor: not-allowed;
  }
`;
