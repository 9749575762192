import React from "react";
import './AbsoluteModal.css'

export const AbsoluteModal = ({ isOpen, children, style }) => {
  return (
    isOpen ?
      (<div className="absolute-modal" style={style}>
        <div className="absolute-modal-content">
          {children}
        </div>
      </div >) : null
  );
};
