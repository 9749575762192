import { CustomButton } from "../customButton/CustomButton";
import { AbsoluteModal } from "../modals/absoluteModal/AbsoluteModal";
import "./GameStartModal.css";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";

import React from "react";

interface GameStartModalProps {
  isOpen: boolean;
  onStart: () => void;
  style?: any;
}

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: <div className="rule-title">1. Discover the Event:</div>,
    children: (
      <p>
        Each round, you'll receive the description of a random historical event.
        Your challenge is to guess the exact date it took place.
      </p>
    ),
  },
  {
    key: "2",
    label: <div className="rule-title">2. Five Rounds, Five Events:</div>,
    children: (
      <p>
        The game consists of five rounds, each featuring a different event from
        history.
      </p>
    ),
  },
  {
    key: "3",
    label: <div className="rule-title">3. Zoom for Clues:</div>,
    children: (
      <p>
        <div>
          Zoom in close to the event on the timeline to reveal more clues. Use
          these clues to make an educated guess!
        </div>

        <ul>
          <li>{`Zoom Level < 40%: Get more relevant information about the event's context.`}</li>
          <li>
            {`Zoom Level < 30%: The specific historical event is fully revealed`}
          </li>
        </ul>
        <img src="/assets/zoom-tutorial.gif" width={"100%"} />
      </p>
    ),
  }
];

export const GameStartModal: React.FC<GameStartModalProps> = ({
  isOpen,
  style,
  onStart,
}) => {
  return (
    <AbsoluteModal isOpen={isOpen} style={style}>
      <div className="game-start-wrapper">
        <div className="game-start-content">
          <h2>Welcome to HistoryGuesser!</h2>
          <h3>How to Play</h3>
          <Collapse accordion items={items} />
          <CustomButton className="game-start-button" onClick={onStart}>
            Start
          </CustomButton>
        </div>
      </div>
    </AbsoluteModal>
  );
};
