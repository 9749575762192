import React, { useEffect, useState } from "react";
import "./HamburgerMenu.css";
import Hamburger from "hamburger-react";

interface HamburgerMenuProps {
  children: any;
  closeId: any;
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  children,
  closeId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(false);
  }, [closeId]);

  return (
    <div className="hamburger-menu">
      <Hamburger
        toggled={isOpen}
        onToggle={() => setIsOpen((prev) => !prev)}
        color={"white"}
      />
      <div
        className="hamburger-menu-content"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        {children}
      </div>
    </div>
  );
};
