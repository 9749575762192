import { PARAMETERS } from "../constants/parameters";
import { ROUTES } from "../routes/routes-const";

  export const generateTimelineLink = (id) => {
    const url = new URL(window.location.href);
    url.pathname = ROUTES.TIMELINE;
    url.searchParams.set(PARAMETERS.ID, id);
    return url.pathname + url.search;
  };

  export const generateEventLink = (id) => {
    const url = new URL(window.location.href);
    url.pathname = ROUTES.TIMELINE;
    url.searchParams.set(PARAMETERS.EVENT, id);
    return url.pathname + url.search;
  };