import { useSelector } from "react-redux";
import "./Profile.css";
import React from "react";
import { Image } from "antd";

interface myProps {}

export const Profile: React.FC<myProps> = ({}) => {
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  return (
    <div>
      <div className="profile-center-lane">
        <div className="profile-info">
          <div className="profile-title">Profile</div>
          <div>
            <span className="profile-name">Nume:</span> {user.username}
          </div>
          <div>
            <span className="profile-email">Email:</span> {user.email}
          </div>
        </div>
        <div>
          <Image width={200} src="/images/profile-picture.jpg" />
        </div>
      </div>
    </div>
  );
};
