import "./HistoryGuesser.css";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { timelineToString } from "../../utils/timelineParser";
import { TimelinesList } from "../../components/timelinesList/TimelinesList";
import { TimelineInstance } from "../../components/timelineWidget/TimelineInstance";
import { Description } from "../../components/description/Description";
import { LeftSidebar } from "../../components/ads/leftSidebar/LeftSidebar";
import dayjs from "dayjs";
import { hideDates } from "../../utils/hideDates";
import { EMPTY_TIMELINE_STRING } from "../../constants/constants";
import { GuesserControls } from "../../components/guesserControls/GuesserControls";
import { PartialModal } from "../../components/modals/partialModal/PartialModal";
import { secondsToScore } from "../../utils/utils";
import { GameStartModal } from "../../components/gameStartModal/GameStartModal";
import { CustomButton } from "../../components/customButton/CustomButton";
import { getGuessTimeline } from "../../redux/actions/timelineActions";

function HistoryGuesser() {
  const timelines = useSelector((state: any) => state.timeline.timelines);
  const guessTimeline = useSelector(
    (state: any) => state.timeline.guessTimeline
  );
  const [randomEvents, setRandomEvents] = useState<any[]>([]);
  const [displayedEventIndex, setDisplayedEventIndex] = useState<number>(-1);
  const [guessScore, setGuessScore] = useState<string>("");
  const [guessInfo, setGuessInfo] = useState<string>("");
  const [totalScore, setTotalScore] = useState<number>(0);
  const [totalScoreOpened, setTotalScoreOpened] = useState<boolean>(false);
  const [startModalOpen, setStartModalOpen] = useState<boolean>(true);
  const [resetId, setResetId] = useState<number>(0);
  const [running, setRunning] = useState<boolean>(false);

  // Used to access in async function
  const selectedEventRef = useRef("");

  const dispatch = useDispatch()
  useEffect(()=>{
    !guessTimeline && dispatch(getGuessTimeline());
  },[])
  useEffect(() => {
    if (timelines.length) {
      const randEvents: { timeline: any; event: any }[] = [];

      for (let i = 0; i < 5; ) {
        const [randomTimeline, randEvent] = getRandomEvent(timelines);
        if (!randEvents.find((e) => e.event._id === randEvent._id)) {
          i++;
          randEvents.push({
            timeline: randomTimeline,
            event: {
              ...randEvent,
              description: hideDates(randEvent.description),
            },
          });
        }
      }

      setRandomEvents(randEvents);
    }
  }, [timelines]);

  const getRandomEvent = (timelines) => {
    const randomTimeline =
      timelines[Math.floor(Math.random() * timelines.length)];
    const randomTimelineEvents = randomTimeline.events;
    const randomEvent =
      randomTimelineEvents[
        Math.floor(Math.random() * randomTimelineEvents.length)
      ];
    return [randomTimeline, randomEvent];
  };

  const getDistance = () => {
    const selectedEvent: any = selectedEventRef.current;
    if (selectedEvent) {
      const focusDate = window.getFocusedDate();
      const correctDate = dayjs(selectedEvent.event.startDate);
      const guessedDate = dayjs(focusDate);

      return {
        miliseconds: Math.abs(correctDate.diff(guessedDate)),
        text: dayjs.duration(correctDate.diff(guessedDate)).humanize(),
        info:
          "Focus date:" +
          focusDate +
          " Guess: " +
          guessedDate.toString() +
          " Correct: " +
          correctDate.toString(),
      };
    }
    return { miliseconds: 0, text: "Error" };
  };

  const onStart = () => {
    setStartModalOpen(false);
    setRunning(true);

    setDisplayedEventIndex(0);
    selectedEventRef.current = randomEvents[0];
  };

  const onGuess = () => {
    const focusDate = window.getFocusedDate();
    setRunning(false);
    const { miliseconds, text, info } = getDistance();
    const score = Math.round(secondsToScore(miliseconds / 1000));

    const selectedEvent: any = selectedEventRef.current;
    window.updateEvent({
      id: selectedEvent.event._id,
      importance: 100,
      high_threshold: 100,
    });

    window.goToMidDateWithTransition(selectedEvent.event.startDate);

    window.createDistanceInEnvelope(selectedEvent.event.startDate, focusDate);
    setGuessScore(score.toString());
    setGuessInfo(text || "");
    setTotalScore(totalScore + score);
  };

  const onNextGuess = () => {
    setGuessScore("");
    setGuessInfo("");
    window.removeDistanceInEnvelope();
    window.scrollTo(0, 0);

    setRunning(true);
    if (displayedEventIndex < 4) {
      setDisplayedEventIndex(displayedEventIndex + 1);
      selectedEventRef.current = randomEvents[displayedEventIndex + 1];
      setResetId((prev) => prev + 1);
    } else {
      setTotalScoreOpened(true);
      setRunning(false);
      setResetId((prev) => prev + 1);
    }
  };

  const onPlayAgain = () => {
    window.location.reload(false);
  };

  const guessSpecificEvents =
    displayedEventIndex >= 0
      ? [
          {
            ...randomEvents[displayedEventIndex].event,
            importance: 50,
            highThreshold: 30,
          },
          ...randomEvents[displayedEventIndex].timeline.events
            .filter(
              (event) =>
                event._id !== randomEvents[displayedEventIndex].event._id
            )
            .map((event) => ({
              ...event,
              importance: 60,
              highThreshold: 40,
            })),
        ]
      : [];

  const guessingTimelineWithEvent = guessTimeline
    ? {
        ...guessTimeline,
        // focusDate: focusDate || guessTimeline.focusDate,
        // initialZoom: zoom || guessTimeline.initialZoom,
        events: [
          ...guessTimeline.events.map((event) => ({
            ...event,
            importance: 90,
          })),
          ...guessSpecificEvents,
        ],
      }
    : undefined;

  const timelineString = guessingTimelineWithEvent
    ? timelineToString(guessingTimelineWithEvent)
    : EMPTY_TIMELINE_STRING;

  return (
    <div className="history-guesser-page">
      <TimelineInstance
        data={timelineString}
        height={"500px"}
        dynamicHeight={true}
        className={
          "history-guesser-timeline " +
          (guessScore ? "" : "timeglider-with-jumping-pin")
        }
        // onTimelineClick={_setClickedTimeline}
      />

      <GameStartModal isOpen={startModalOpen} onStart={onStart} />

      <div className="game-fixed-layer">
        <PartialModal isOpen={guessScore} style={{ zIndex: 1000 }}>
          <div className="guess-modal-content">
            <span className="guess-title">Guess score: {guessScore}</span>
            <span className="guess-subtitle">Guess distance: {guessInfo}</span>
            <CustomButton className="game-start-button" onClick={onNextGuess}>Next</CustomButton>
          </div>
        </PartialModal>
        <PartialModal isOpen={totalScoreOpened} style={{ zIndex: 1000 }}>
          <div className="guess-modal-content">
            <span className="guess-title">Result</span>
            <span className="guess-subtitle">{totalScore}</span>
            <CustomButton className="game-start-button" onClick={onPlayAgain}>Play again</CustomButton>
          </div>
        </PartialModal>

        <GuesserControls
          currentRound={displayedEventIndex + 1}
          totalScore={totalScore}
          onGuess={onGuess}
          resetId={resetId}
          running={running}
          isDisabled={guessScore || totalScoreOpened}
        />
      </div>

      <div className="history-guesser-content-wrapper">
        <div className="history-guesser-content">
          <div className="left-lane">
            <LeftSidebar />
          </div>
          <div className="center-lane">
            {displayedEventIndex >= 0 && (
              <Description event={randomEvents[displayedEventIndex].event} isGuesser={true}/>
            )}
          </div>
          <div className="right-lane">
            <TimelinesList timelines={timelines} limit={4}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryGuesser;
