import React from 'react'
import "./CategoryList.css";
import { TimelineCard } from "../timelineCard/TimelineCard1";
import { TimelineCardPlaceholder } from "../timelineCardPlaceholder/TimelineCardPlaceholder1";

interface CategoryListProps {
name: string,
timelines: any[],
}
export const CategoryList: React.FC<CategoryListProps> = ({ name, timelines }) => {
  return (
    <div className="category-list">
      <span className="category-name">{name}</span>
      <div className="category-items">
        {timelines.length > 0 ?
          timelines
            .map((timeline) => (
              <TimelineCard
                timeline={timeline}
                key={timeline._id}
              ></TimelineCard>
            )):
            Array.from(Array(8).keys())
            .map((index) => (
              <TimelineCardPlaceholder
                key={"card-" + index}
              ></TimelineCardPlaceholder>
            ))
            }
      </div>
    </div>
  );
};
