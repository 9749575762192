import React from "react";
import "./LeftSidebar.css";
import { AffiliateAd } from "../affiliateAd/AffiliateAd";
import { useScreenSize } from "../../useScreenSize/useScreenSize";
import useElementSize from "../../useElementSize/useElementSize";

interface myParams {
  timeline?: any;
}

export const LeftSidebar = React.memo<myParams>(({ timeline }) => {
  const screenSize = useScreenSize();
  const isMobile = screenSize < 768;
  const isTablet = screenSize < 1300;

  const [ref, size] = useElementSize();

  const getAddForSize = () => {
    if (isMobile) return <AffiliateAd width={250} height={250} />;
    if (isTablet) return <AffiliateAd width={160} height={600} />;
    return <AffiliateAd width={300} height={600} />;
  };

  const numberOfAds = Math.floor((size.height || 0) / 600 / 2) || 0;
  return (
    <div className="left-sidebar" ref={ref}>
      {isMobile ? (
        <AffiliateAd width={250} height={250} />
      ) : (
        [...Array(numberOfAds)].map((_, i) => (
          <div key={i}>{getAddForSize()}</div>
        ))
      )}
    </div>
  );
});
