export const SET_TIMEGLIDER_VISIBLE = 'SET_TIMEGLIDER_VISIBLE';
export const SET_TIMEGLIDER_DATA = 'SET_TIMEGLIDER_DATA';
export const SET_TIMEGLIDER_HEIGHT = 'SET_TIMEGLIDER_HEIGHT';
export const SET_TIMEGLIDER_STYLE = 'SET_TIMEGLIDER_STYLE';

export const GET_TIMELINE = 'GET_TIMELINE';
export const ADD_TIMELINE = 'ADD_TIMELINE';
export const UPDATE_TIMELINE = 'UPDATE_TIMELINE';
export const DELETE_TIMELINE = 'DELETE_TIMELINE';

export const ADD_TIMELINES = 'ADD_TIMELINES';

export const ADD_USER_TIMELINES = 'ADD_USER_TIMELINES';

export const SET_ACTIVE_TIMELINE = 'SET_ACTIVE_TIMELINE';

export const SET_DISPLAYED_TIMELINE = 'SET_DISPLAYED_TIMELINE';

export const SET_GUESS_TIMELINE = 'SET_GUESS_TIMELINE';

export const GET_EVENT = 'GET_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY';
