// const baseUrl = "http://localhost:5000";
// const baseUrl = "https://timetrail.online";
const baseUrl = "";

export const postData = async (url = "", data = {}, authToken) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["x-auth-token"] = authToken;
  }

  const response = await fetch(baseUrl + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  if (!response.ok) {
    const msg = await response.json();
    throw { msg, status: response.status };
  }

  try {
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (e) {
    return null;
  }
};

export const putData = async (url = "", data = {}, authToken) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["x-auth-token"] = authToken;
  }

  const response = await fetch(baseUrl + url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  if (!response.ok) {
    const msg = await response.json();
    throw { msg, status: response.status };
  }

  try {
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (e) {
    return null;
  }
};

export const deleteData = async (url = "", data = {}, authToken) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["x-auth-token"] = authToken;
  }

  const response = await fetch(baseUrl + url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  if (!response.ok) {
    const msg = await response.json();
    throw { msg, status: response.status };
  }

  try {
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (e) {
    return null;
  }
};

export const fetchData = async (url = "", authToken) => {
  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "public, max-age=1, s-maxage=86400",
  };

  if (authToken) {
    headers["x-auth-token"] = authToken;
  }

  const response = await fetch(baseUrl + url, {
    headers,
  });

  if (!response.ok) {
    const msg = await response.json();
    throw { msg, status: response.status };
  }

  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};
