import "./Feedback.css";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  emailVerification,
  feedbackLimit,
  required,
} from "../../utils/formValidators";
import { Input, Label } from "../styled-components";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { postData } from "../../utils/api";
import { EMAIL_STATE } from "../../constants/emailState";
import { CustomButton } from "../customButton/CustomButton";

export const Feedback = () => {
  const sendFeedback = (data) => {
    postData("/api/email", data)
      .then((res) => {
        if (res.success === true) {
          setShow(false);
          setEmailState(EMAIL_STATE.SENT);
        } else {
          setEmailState(EMAIL_STATE.ERROR);
        }
      })
      .catch((err) => {
        setEmailState(EMAIL_STATE.ERROR);
      });
  };
  const [show, setShow] = useState(false);
  const [emailState, setEmailState] = useState(EMAIL_STATE.NOT_SENT);

  const _reloadCapcha = () => {
    loadCaptchaEnginge(5);
  };

  const _setShow = (show) => {
    setShow(show);
    if (show) {
      setTimeout(() => {
        loadCaptchaEnginge(5);
      }, 0);
    }
  };

  const _validateCapcha = (capcha) => {
    return validateCaptcha(capcha, false) ? undefined : "Invalid";
  };

  return (
    <div className="feedback-wrapper">
      <CustomButton size="small" onClick={() => _setShow(true)}>Feedback</CustomButton>
      {show && (
        <div className="feedback-content">
          <Form
            onSubmit={(data) => {
              sendFeedback(data);
            }}
          >
            {({ handleSubmit }) => (
              <form className="feedback-form" onSubmit={handleSubmit}>
                <div className="feedback-header">
                  <span className="feedback-header-title">Feedback</span>
                </div>
                <div className="feedback-inputs">
                  <Label>Email</Label>
                  <Field name="email" validate={emailVerification}>
                    {({ input, meta }) => (
                      <div className="form-input-wrapper">
                        <Input
                          className="form-input feedback-email"
                          type="email"
                          placeholder="example@gmail.com"
                          {...input}
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>

                  <Label>Feedback</Label>
                  <Field
                    name="feedback"
                    validate={composeValidators(required, feedbackLimit)}
                  >
                    {({ input, meta }) => (
                      <div className="form-input-wrapper">
                        <textarea
                          {...input}
                          className="form-input feedback-text-area"
                          name="text-area"
                          rows="8"
                          cols="30"
                          placeholder="Type any feedback here..."
                        ></textarea>
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    name="capcha"
                    validate={composeValidators(required, _validateCapcha)}
                  >
                    {({ input, meta }) => (
                      <div>
                        <Label>Capcha</Label>
                        <div className="form-input-capcha">
                          <div className="capcha-canvas">
                            <LoadCanvasTemplateNoReload />
                          </div>
                          <div
                            className="reload-capcha"
                            onClick={() => _reloadCapcha()}
                          >
                            <span>&#x21bb;</span>
                          </div>
                          <Input
                            className="form-input feedback-capcha"
                            type="text"
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="feedback-error">{emailState === EMAIL_STATE.ERROR && 'There was an error sending the feedback'}</div>
                <div className="feedback-buttons">
                  <CustomButton className="form-input" type="primary" onClick={handleSubmit} size="small">
                    Send Feedback
                  </CustomButton>
                  <CustomButton
                    className="form-input"
                    type="secondary"
                    size="small"
                    onClick={() => _setShow(false)}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </form>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};
