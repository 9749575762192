import React, { useState } from "react";
import "./AffiliateAd.css";
import {
  AD_LOAD_DELAY,
  AD_REFRESH_INTERVAL,
} from "../../../constants/constants";
import { shuffleArray } from "../../../utils/utils";
import CAMPAIGNS from "../../../ads/Assets.json";
import { useSelector } from "react-redux";

interface myParams {
  width: number;
  height: number;
}

export const AffiliateAd = React.memo<myParams>(({ width, height }) => {
  const country = useSelector((state) => state.auth.country);
  const [refresh, setRefresh] = useState(0);
  setTimeout(() => {
    setRefresh(refresh + 1);
  }, AD_REFRESH_INTERVAL);

  const [adsLoaded, setAdsLoaded] = useState(false);
  setTimeout(() => {
    setAdsLoaded(true);
  }, AD_LOAD_DELAY);

  if (!adsLoaded)
    return <div className={`affiliate-ad`} style={{ width, height }} />;

  const sizesMatch = (a, b) => {
    if (Math.abs(a - b) < 10) return true;
    return false;
  };

  const adIsCorrectSize = (ad) =>
    // This is switched in the response, it must be a mistake in the csv
    (sizesMatch(parseInt(ad.ThirdPartyServableAdCreativeWidth), height) &&
      sizesMatch(parseInt(ad.ThirdPartyServableAdCreativeHeight), width)) ||
    (sizesMatch(parseInt(ad.ThirdPartyServableAdCreativeWidth), height * 2) &&
      sizesMatch(parseInt(ad.ThirdPartyServableAdCreativeHeight), width * 2));

  const validCampaigns = CAMPAIGNS.filter(
    (campaign) =>
      !country ||
      !campaign.countries.length ||
      campaign.countries.includes(country)
  ).filter((campaign) => {
    for (const ad of campaign.ads) {
      if (adIsCorrectSize(ad)) {
        return true;
      }
    }
    return false;
  });

  const validAds = (shuffleArray(validCampaigns)[0]?.ads || []).filter(
    adIsCorrectSize
  );

  const getPixelUrl = (ad) => {
    return ad.TrackingLink.split("/").slice(-3).join("/").split("?")[0];
  };

  const ad = shuffleArray(validAds)[0];

  if (!ad) return null;

  return (
    <div
      className={`affiliate-ad affiliate-ad-${width}x${height}`}
      style={{ width, height }}
    >
      <a href={ad.TrackingLink} target="_top" id={ad.AdId}>
        <img
          src={
            "//a.impactradius-go.com/display-ad/" +
            ad.CampaignId +
            "-" +
            ad.AdId
          }
          border="0"
          alt=""
          width={width}
          height={height}
        />
      </a>
      <img
        height="0"
        width="0"
        src={"https://imp.pxf.io/i/" + getPixelUrl(ad)}
        style={{ position: "absolute", visibility: "hidden" }}
        border="0"
      />
    </div>
  );
});
