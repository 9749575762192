import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setTimegliderVisible,
  setTimegliderData,
  setTimegliderHeight,
  setTimegliderStyle,
} from "../../redux/actions/timegliderActions";

interface myParams {
  data: string;
  height: string;
  style?: any;
  className?: string;
  dynamicHeight?: boolean;
  onEventClick?: (id: string) => void;
  onTimelineClick?: (data: {
    date: string;
    focusDate: string;
    zoom: number;
  }) => void;
}

export const TimelineInstance: React.FC<myParams> = ({
  data = "",
  height = "",
  style = {},
  className = "",
  dynamicHeight=false,
  onEventClick,
  onTimelineClick,
}: myParams) => {
  const dispatch = useDispatch<any>();
  const [reloaded, setReloaded] = useState(0);

  const _clickEvent = ((event: CustomEvent) => {
    if (onEventClick) {
      onEventClick(event.detail);
    }
  }) as EventListener;

  const _timelineClick = ((event: CustomEvent) => {
    if (onTimelineClick) {
      onTimelineClick(event.detail);
    }
  }) as EventListener;

  const timegliderReload = () => {
    setReloaded((prev) => prev + 1);
    window.removeEventListener("timegliderReloaded", timegliderReload);
  };

  useEffect(() => {
    window.addEventListener("timegliderReloaded", timegliderReload);
    dispatch(setTimegliderVisible(true));
    window.addEventListener("clickEvent", _clickEvent);
    return () => {
      dispatch(setTimegliderVisible(false));
      window.removeEventListener("clickEvent", _clickEvent);
    };
  }, []);

  useEffect(() => {
    if (reloaded > 0) {
      window.setClassToPlacement(className);
    }
  }, [className, reloaded]);

  useEffect(() => {
    if (reloaded > 0) {
      window.setDynamicHeight(dynamicHeight);
    }
  }, [dynamicHeight, reloaded]);

  useEffect(() => {
    window.addEventListener("timelineClick", _timelineClick);
    return () => {
      window.removeEventListener("timelineClick", _timelineClick);
    };
  }, []);

  useEffect(() => {
    dispatch(setTimegliderData(data));
  }, [data]);

  useEffect(() => {
    dispatch(setTimegliderHeight(height));
  }, [height]);

  useEffect(() => {
    dispatch(setTimegliderStyle(style));
  }, [style]);

  return null;
};
