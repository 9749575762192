import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import usePrevious from "./usePrevious";

export const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  const previousPathname = usePrevious(pathname);
  const previousSearch = usePrevious(search);

  useEffect(() => {
    const oldParams = new URLSearchParams(previousSearch);
    const newParams = new URLSearchParams(search);
    if (
      previousSearch &&
      previousPathname === pathname &&
      oldParams.get("id") === newParams.get("id")
    )
      return;

    window.scrollTo(0, 0);
    // Added for mobile devices that scroll back to bottom after rerender
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname, search]);

  return null;
};
