import "./About.css";

const About = () => {
  return (
    <div className="about">
      <div className="about-info">
        <p className="about-text">Hi!</p>
        <p className="about-text">
          Welcome to TimeTrail, where history comes alive through interactive timelines!
        </p>
        <p className="about-text">
          At TimeTrail, we believe that understanding history is key to understanding our present and shaping our future. That's why we've dedicated ourselves to creating a unique digital platform that brings historical events to life through engaging, interactive timelines.
        </p>
        <p className="about-text">
          Our mission is simple but profound: to make history accessible, engaging, and enlightening for everyone. Whether you're a student, educator, historian, or simply a curious mind, TimeTrail offers a dynamic way to explore the past. We strive to present historical facts in an intuitive and interactive format that not only educates but also entertains.
        </p>
        <p className="about-text">
          For any question, please contact us at{" "}
          <strong>contact.timetrail@gmail.com</strong>
        </p>
      </div>
    </div>
  );
};

export default About;