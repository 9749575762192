import { useRef, useState, useEffect } from 'react';

function useElementSize() {
  const elementRef = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      }
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return [elementRef, size];
}

export default useElementSize;