import "./App.css";
import { Routes } from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import TimelineWidget from "./components/timelineWidget/TimelineWidget";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { Meta } from "./components/meta/Meta";
import { ThemeProvider } from "styled-components";
import { store } from "./redux";
import { Theme } from "./components/styled-components/Theme";
import { ScrollToTop } from "./utils/scrollToTop";

Modal.setAppElement("#root");

const App = () => {
  return (
    <div className="app">
      <header className="app-header"></header>

      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          {/* Wrapping everything in Router so we can access history inside all of the components */}
          <Router>
            <ScrollToTop />
            <Meta />
            <Header />
            <TimelineWidget />
            <Routes />
            <Footer />
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  );
};

export default App;
