import { SET_TIMEGLIDER_VISIBLE, SET_TIMEGLIDER_DATA, SET_TIMEGLIDER_HEIGHT, SET_TIMEGLIDER_STYLE } from "./types";

export const setTimegliderVisible = (data) => async (dispatch) => {
    dispatch({
      type: SET_TIMEGLIDER_VISIBLE,
      payload: data,
    });
};

export const setTimegliderData = (data) => async (dispatch) => {
    dispatch({
      type: SET_TIMEGLIDER_DATA,
      payload: data,
    });
};

export const setTimegliderHeight = (data) => async (dispatch) => {
    dispatch({
      type: SET_TIMEGLIDER_HEIGHT,
      payload: data,
    });
};

export const setTimegliderStyle = (data) => async (dispatch) => {
    dispatch({
      type: SET_TIMEGLIDER_STYLE,
      payload: data,
    });
};

