export const required = (value) => (value ? undefined : "Required");

export const mustBeNumber = (value) =>
  isNaN(value) ? "Must be a number" : undefined;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const usernameLimit = (value) =>
  value && value.length > 30
    ? "The value exceeds the character limit"
    : undefined;
export const titleLimit = (value) =>
  value && value.length > 50
    ? "The value exceeds the character limit"
    : undefined;
export const imageHeightLimit = (value) =>
  value && value > 150
    ? "The value exceeds the character limit"
    : undefined;
export const descriptionLimit = (value) =>
  value && value.length > 2500
    ? "The value exceeds the character limit"
    : undefined;

export const feedbackLimit = (value) =>
  value && value.length > 1500
    ? "The value exceeds the character limit"
    : undefined;
export const amazonAdsLimit = (value) =>
  value && value.length > 10000
    ? "The value exceeds the character limit"
    : undefined;
export const imageLimit = (value) =>
  value && value.length > 1000
    ? "The value exceeds the character limit"
    : undefined;
export const emailVerification = (value) =>
  value && value.length > 30 ? "Please insert a valid email" : undefined;
export const passwordVerification = (value) =>
  value && value.length > 30 ? "Please insert a valid password" : undefined;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
