import styled from "styled-components";

export const Input = styled.input`
  appearance: none;
  border-radius: 10px;
  padding: 0.6rem 0.5rem;
  outline: none;
  font-size: ${(props) => props.theme.Size.normalSize};
  background: ${(props) => props.theme.Color.White};
  width: ${(props) => props.widthComponent};
  height: ${(props) => props.heightComponent};
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  color: ${(props) => {
    switch (props.background) {
      case "primary":
        return props.theme.Color.Primary;
      case "secondary":
        return props.theme.Color.Secondary;
      case "third":
        return props.theme.Color.Ternary;
      case "succeed":
        return props.theme.Color.Primary;
      case "failed":
        return props.theme.Color.Error;
      default:
        return props.theme.Color.Secondary;
    }
  }};
  border: 1px solid
    ${(props) => {
      switch (props.background) {
        case "primary":
          return props.theme.Color.WaikawaGrey;
        case "secondary":
          return props.theme.Color.DodgerBlue;
        case "third":
          return props.theme.Color.DarkBlue;
        case "succeed":
          return props.theme.Color.Green;
        case "failed":
          return props.theme.Color.Orange;
        default:
          return props.theme.Color.Black;
      }
    }};
`;
