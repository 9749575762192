import { combineReducers } from "redux";

import { timelineReducer } from "./timelineReducer";
import { timegliderReducer } from "./timegliderReducer";
import { errorReducer } from "./errorReducer";
import { authReducer } from "./authReducer";

export const rootReducer = combineReducers({
  timeline: timelineReducer,
  timeglider: timegliderReducer,
  error: errorReducer,
  auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;