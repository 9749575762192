import React from "react";
import "./CategoryListNew.css";
import { TimelineCard } from "../timelineCard/TimelineCard1";
import { TimelineCardPlaceholder } from "../timelineCardPlaceholder/TimelineCardPlaceholder1";

interface CategoryListNewProps {
  name: string;
  timelines: any[];
}
export const CategoryListNew: React.FC<CategoryListNewProps> = ({
  name,
  timelines,
}) => {
  return (
    <div className="category-list-new">
      <span className="category-name-new">{name}</span>
      <div className="category-items-new">
        {timelines.length > 0
          ? timelines.map((timeline) => (
              <TimelineCard
                timeline={timeline}
                key={timeline._id}
              ></TimelineCard>
            ))
          : Array.from(Array(3).keys()).map((index) => (
              <TimelineCardPlaceholder
                key={"card-" + index}
              ></TimelineCardPlaceholder>
            ))}
      </div>
    </div>
  );
};
