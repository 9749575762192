import { EXTERNAL_ROUTES, ROUTES } from "../../routes/routes-const";
import { Feedback } from "../feedback/Feedback";
import { NavButton } from "../navButton/NavButton";
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="scroll-to-top" onClick={() => window.scrollTo({ top: 0, behavior: "smooth", })}></div>
      <footer className="footer">
        <div className="footer-line-1">
          <span className="footer-text">TIMETRAIL © {(new Date()).getFullYear()}</span>
          <div className="page-links">
            <NavButton href={ROUTES.ABOUT}>About</NavButton>
            <NavButton href={ROUTES.TERMS}>Terms and Services</NavButton>
            <NavButton href={ROUTES.PRIVACY}>Privacy Policy</NavButton>
            <NavButton href={ROUTES.DISCLAIMER} >Disclaimer</NavButton>
          </div>
          <div className="social-links">
            <a href={EXTERNAL_ROUTES.REDDIT}><div className="social-icon reddit-icon" /></a>
            <a href={EXTERNAL_ROUTES.X}><div className="social-icon x-icon" /></a>
          </div>
        </div>
        <div className="footer-line-2">
          <div className="footer-line-text">
            <p><strong>Affiliate</strong></p>
            <span>
              TimeTrail is a participant in the Amazon Services LLC Associates
              Program, an affiliate advertising program designed to provide a
              means for sites to earn advertising fees by advertising and
              linking to Amazon.com.
            </span>
          </div>

          <div className="footer-line-text">
            <p><strong>Feedback:</strong></p>
            <p>
              Your feedback is extremely valuable to us and plays a crucial role in shaping the direction of our platform.
              We would love to hear your thoughts and suggestions.
              Please feel free to reach out to us at <strong>contact.timetrail@gmail.com</strong> or use the built-in feedback form to share your insights.
            </p>
            <Feedback />
          </div>
        </div>
      </footer>
    </div >
  );
};
