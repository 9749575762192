import React from "react";
import "./HistoryGuesserPlay.css";
import { ROUTES } from "../../routes/routes-const";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

interface myParams {
  mode: 'horizontal' | 'box';
}

export const HistoryGuesserPlay: React.FC<myParams> = ({ mode = "horizontal" }) => {
  const history = useHistory();

  const goToHistoGuesser = () => {
    const url = new URL(window.location.href);
    url.pathname = ROUTES.GUESSER;
    history.push({ pathname: url.pathname });
  };

  if (mode === "horizontal")
    return (
      <div className="histo-guesser-play-wrapper" onClick={goToHistoGuesser}>
        <img
          src="/assets/history-guesser-play.png"
          className="histo-guesser-play-image"
          alt="History guesser play button"
        />
      </div>
    );

  if (mode === "box")
    return (
      <div
        className="histo-guesser-play-wrapper-box"
        onClick={goToHistoGuesser}
      >
        <img
          src="/assets/history-guesser-play-box.png"
          className="histo-guesser-play-image-box"
          alt="History guesser play button"
        />
      </div>
    );
};
