import React from 'react'
import "./TimelinesList.css";
import { TimelineCard } from "../timelineCard/TimelineCard1";
import { shuffleArray } from "../../utils/utils";
import { useMemo } from "react";
import { HistoryGuesserPlay } from "../history-guesser-play/HistoryGuesserPlay";

interface myParams {
  timelines: any[];
  limit?: number;
}

export const TimelinesList: React.FC<myParams> = ({ timelines, limit }) => {
  timelines = useMemo(() => shuffleArray(timelines), [timelines]);
  const displayedTimelines = limit ? timelines.slice(0, limit) : timelines;
  return (
    <div className="timelines-list">
      {displayedTimelines.length > 0 &&
        displayedTimelines.map((timeline, index) => (
          <TimelineCard
            timeline={timeline}
            key={"timeline-" + index}
          ></TimelineCard>
        ))}

      <HistoryGuesserPlay mode={"box"} />
    </div>
  );
};
