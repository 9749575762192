import React from "react";
import "./Header.css";
import { Logo } from "./logo.tsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadUser, setUserCountry } from "../../redux/actions/authActions.js";
import {
  getTimelines,
  getUserTimelines,
} from "../../redux/actions/timelineActions.js";
import { useScreenSize } from "../useScreenSize/useScreenSize.tsx";
import { NavButton } from "../navButton/NavButton.tsx";
import { LogoSmall } from "./logoOld.tsx";
import { HamburgerMenu } from "../hamburgerMenu/HamburgerMenu.tsx";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../routes/routes-const.js";
import { getValueByKey } from "../../utils/utils.js";
import { UserMenu } from "../userMenu/UserMenu.tsx";

export const Header = () => {
  const url = new URL(window.location.href);
  const isAdmin = url.searchParams.get("admin");
  const forceCounty = url.searchParams.get("country");

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const timelines = useSelector((state) => state.timeline.timelines);
  const userTimelines = useSelector((state) => state.timeline.userTimelines);
  const displayedTimelineId = useSelector(
    (state) => state.timeline.displayedTimelineId
  );

  const [hamburgerClose, setHamburgerClose] = useState(0);

  const screenSize = useScreenSize();
  const isMobileOrTablet = screenSize < 1300;

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, []);

  useEffect(() => {
    timelines.length === 0 && dispatch(getTimelines());
  }, []);

  useEffect(() => {
    userTimelines.length === 0 &&
      isAuthenticated &&
      dispatch(getUserTimelines());
  }, [userTimelines, isAuthenticated]);

  useEffect(() => {
    setTimeout(() => {
      getUserCountry();
    }, 5000);
  }, []);

  const getUserCountry = async () => {
    try {
      if (forceCounty === "all") return;
      if (forceCounty) {
        dispatch(setUserCountry(forceCounty));
        return;
      }
      const text = await (
        await fetch("https://time-trail.com/cdn-cgi/trace")
      ).text();
      const country = getValueByKey(text, "loc");
      dispatch(setUserCountry(country));
    } catch {
      console.log("Error getting the country!");
    }
  };

  const displayedTimeline =
    timelines.find((item) => item._id === displayedTimelineId) || {};

  const goToHome = () => {
    const url = new URL(window.location.href);
    url.pathname = ROUTES.HOME;
    history.push({ pathname: url.pathname });
  };

  const logoLink = () => (
    <div
      style={{ height: "80%", marginLeft: "10px", cursor: "pointer" }}
      onClick={goToHome}
    >
      <Logo className="header-logo" style={{ height: "100%" }} />
    </div>
  );

  const logoOldLink = () => (
    <div
      style={{ height: "60%", marginLeft: "10px", cursor: "pointer" }}
      onClick={goToHome}
    >
      <LogoSmall className="header-logo" style={{ height: "100%" }} />
    </div>
  );

  const closeHamburger = () => {
    if (isMobileOrTablet) {
      setHamburgerClose((prev) => prev + 1);
    }
  };

  const navigationLinks = () => (
    <>
      <NavButton
        href={ROUTES.HOME}
        className="header-navigation-home"
        onClick={closeHamburger}
      >
        Home
      </NavButton>
      <NavButton href={ROUTES.ABOUT} onClick={closeHamburger}>
        About
      </NavButton>
      <NavButton href={ROUTES.TERMS} onClick={closeHamburger}>
        Terms
      </NavButton>
      <NavButton href={ROUTES.PRIVACY} onClick={closeHamburger}>
        Privacy Policy
      </NavButton>
      <NavButton href={ROUTES.DISCLAIMER} onClick={closeHamburger}>
        Disclaimer
      </NavButton>

      {(isAdmin || isAuthenticated) && <UserMenu />}
    </>
  );

  return (
    <div className="header">
      <div className="header-placeholder"></div>
      <div className="header-fixed">
        <div className="left-wrapper">
          {!isMobileOrTablet && logoLink()}
          {isMobileOrTablet && logoOldLink()}
        </div>

        <div className="middle-wrapper">
          {!displayedTimeline._id ? (
            isMobileOrTablet ? (
              logoLink()
            ) : (
              <div />
            )
          ) : (
            <span className="timeline-title">{displayedTimeline.title}</span>
          )}
        </div>

        <div className="right-wrapper">
          {!isMobileOrTablet ? (
            <div className="header-navigation">{navigationLinks()}</div>
          ) : (
            <HamburgerMenu closeId={hamburgerClose}>
              {navigationLinks()}
            </HamburgerMenu>
          )}
        </div>
      </div>
    </div>
  );
};
