const Color = {
  DodgerBlue: "#0499FF",
  LinkWaterGrey: "#D8DBE5",
  SapphireBlue: "#0C2E6E",
  WaikawaGrey: "#6279aa",
  LightPurple: "#5779FF",
  VeryLightBlue: "#F3F6F9",
  DarkBlue: "#0B2559",
  White: "#ffffff",
  MediumGrey: "#e1e2e4",
  Black: "#010205",
  Orange: "#F92D01",
  Green: "#00CB14",

  Primary: "#13aa52",
  Secondary: "#273742",
  Tertiary:"#116149",
  Error: "#F92D01",
};

const Size = {
  bigSize: "3rem",
  largeSize: "2rem",
  mediumSize: "1.5rem",
  normalSize: "1rem",
  smallSize: "0.8rem",
};

export const Theme = {
  Color,
  Size,
};
