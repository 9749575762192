import React from "react";
import './PartialModal.css'

export const PartialModal = ({ isOpen, children, style }) => {
  return (
    isOpen ?
      (<div className="partial-modal" style={style}>
        <div className="partial-modal-content">
          {children}
        </div>
      </div >) : null
  );
};
