export const ROUTES = {
  HOME: "/",
  TIMELINE: "/timeline",
  GUESSER: "/history-guesser",
  ABOUT: "/about",
  DISCLAIMER: "/disclaimer",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  ADD_TIMELINE: "/add-timeline",
  EDIT_TIMELINE: "/edit-timeline",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
};

export const EXTERNAL_ROUTES = {
  REDDIT: "https://www.reddit.com/r/timetrail",
  X: "https://twitter.com/time_trail_com",
};
