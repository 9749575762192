import React from "react";
import { CountdownTimer } from "../countdownTimer/CountdownTimer";
import "./GuesserControls.css";
import { CustomButton } from "../customButton/CustomButton";

export const GuesserControls = ({
  currentRound,
  totalScore,
  onGuess,
  resetId,
  running,
  style={},
  isDisabled,
}) => {
  return (
    <div className="history-guesser-controls" style={style}>
      <div className="game-info">
        <span className="game-info-text">Round: {currentRound}/5</span>
        <span className="game-info-text">
          Score: {totalScore || 0}
        </span>
      </div>
      <CustomButton className="game-guess-button" onClick={onGuess} diabled={isDisabled}>
        Guess
      </CustomButton>
      <div className="game-timer">
        <CountdownTimer reset={resetId} running={running} onFinish={onGuess} />
      </div>
    </div>
  );
};
