import "./Timeline.css";
import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { timelineToString } from "../../utils/timelineParser";
import { TimelinesList } from "../../components/timelinesList/TimelinesList";
import { TimelineInstance } from "../../components/timelineWidget/TimelineInstance";
import { EMPTY_TIMELINE_STRING } from "../../constants/constants";
import { Redirect } from "react-router";
import { SET_DISPLAYED_TIMELINE } from "../../redux/actions/types";
import { LeftSidebar } from "../../components/ads/leftSidebar/LeftSidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PARAMETERS } from "../../constants/parameters";
import usePrevious from "../../utils/usePrevious";
import { FullDescription } from "../../components/fullDescription/FullDescription";
import { PlayerControls } from "../../components/playerControls/PlayerControls";
import { AudioPlayProgress } from "../../components/description/Description";
import { getTimeline } from "../../redux/actions/timelineActions";
import { AudioPointer } from "../../components/audio-pointer/AudioPointer";
import { useScreenSize } from "../../components/useScreenSize/useScreenSize";

function Timeline() {
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  const e = url.searchParams.get("e");
  const [timeoutId, setTimeoutId] = useState(undefined);

  const timelines = useSelector((state: any) => state.timeline.timelines);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTimeline(id));
    return () => dispatch({ type: SET_DISPLAYED_TIMELINE, payload: undefined });
  }, []);

  const displayedTimeline = useSelector(
    (state: any) => state.timeline.displayedTimeline
  );

  const previousDisplayedTimelineId = usePrevious(displayedTimeline?._id);

  if (displayedTimeline?.events) {
    displayedTimeline.events = displayedTimeline.events?.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
  }

  const [clickedEvent, setClickedEvent] = useState({ id: "" });

  // Used to access in async function
  const clickedEventRef = useRef({ id: "" });

  const rightLaneTimelines = useMemo(
    () => timelines?.filter((t) => t._id !== displayedTimeline?._id) || [],
    [timelines]
  );

  useEffect(() => {
    if (e && displayedTimeline) {
      clearTimeout(timeoutId);
      const _timeoutId = setTimeout(() => {
        _setClickedEvent(e || "");
      }, 1000);

      setTimeoutId(_timeoutId);
    }
  }, [e, displayedTimeline]);

  useEffect(() => {
    setTimeout(() => {
      if (!clickedEventRef.current?.id) {
        window.addClassToMiddleEvent("click-indicator-animation");
      }
    }, 15000);
  }, []);

  const displayEventWithHistory = (event) => {
    const url = new URL(window.location.href);
    if (event) {
      url.searchParams.set(PARAMETERS.EVENT, event?._id || "");
    } else {
      url.searchParams.delete(PARAMETERS.EVENT);
    }
    history.push({ pathname: url.pathname, search: url.search });
  };

  useEffect(() => {
    if (previousDisplayedTimelineId !== undefined) {
      displayEventWithHistory(null);
    }
  }, [displayedTimeline]);

  const _setClickedEvent = (id: string) => {
    clickedEventRef.current.id = id;
    setClickedEvent({ id });
  };

  useEffect(() => {
    if (clickedEvent.id && displayedTimeline) {
      window.removeClassFromEvents("click-indicator-animation");

      const event = displayedTimeline.events.find(
        (item: any) => item._id === clickedEvent.id
      );
      if (event) {
        displayEventWithHistory(event);
      }
    }
  }, [clickedEvent]);

  // Audio player ==============================================
  const screenSize = useScreenSize();
  const isMobile = screenSize < 768;
  const [isPlaying, setIsPlaying] = useState(false);
  const [synthReset, setSynthReset] = useState(0);
  const [playingAudioProgress, setPlayingAudioProgress] =
    useState<AudioPlayProgress>({
      id: "",
      charIndex: 0,
      charLength: 1,
      progress: undefined,
    });
  const [playIntervalId, setPlayIntervalId] = useState<
    NodeJS.Timeout | undefined
  >();
  const audioRef = useRef<HTMLAudioElement>();
  const currentAudioIndexRef = useRef(-1);

  useEffect(() => {
    playIntervalId && clearInterval(playIntervalId);
    setPlayIntervalId(undefined);

    if (isPlaying && isMobile) {
      const intervalId = setInterval(() => {
        const currentSec = audioRef.current?.currentTime || 0.001;
        const totalSec = audioRef.current?.duration || 0.1;

        const currentIndex = currentAudioIndexRef.current;
        const playingEvent = getEventByIndex(currentIndex);
        setPlayingAudioProgress({
          id: playingEvent._id,
          progress: currentSec / totalSec,
        });
      }, 300);

      setPlayIntervalId(intervalId);
    }

    return () => playIntervalId && clearInterval(playIntervalId);
  }, [isPlaying]);

  useEffect(() => {
    if (displayedTimeline) {
      audioRef.current = new Audio(
        generateUrlForAudioEvent(displayedTimeline._id)
      );
      audioRef.current.addEventListener("ended", function () {
        playNext();
      });
    }

    return () => {
      audioRef.current?.pause();
      audioRef.current?.removeAttribute("src");
      setIsPlaying(false);
    };
  }, [displayedTimeline]);

  const playNext = () => {
    const newIndex = currentAudioIndexRef.current + 1;
    playIndex(newIndex);
  };

  const playPrevious = () => {
    const newIndex = currentAudioIndexRef.current + -1;
    playIndex(newIndex);
  };

  const playIndex = (newIndex) => {
    setIsPlaying(false);

    const newEvent = getEventByIndex(newIndex);
    const newUrl = generateUrlForAudioEvent(newEvent._id);

    setSynthReset(prev=>prev+1)
    audioRef.current?.pause();
    audioRef.current?.setAttribute("src", newUrl);
    audioRef.current?.load();
    audioRef.current?.play();

    if (newIndex >= 0) {
      _setClickedEvent(newEvent._id);
      window.goToDateWithTransition(newEvent.startDate);
    }

    currentAudioIndexRef.current = newIndex;
    setIsPlaying(true);
  };

  const onPlayAudioForEvent = (event) => {
    if (event._id === displayedTimeline._id) {
      playIndex(-1);
      return;
    }

    const eventIndex = displayedTimeline.events.findIndex(
      (e) => e._id === event._id
    );
    playIndex(eventIndex);
  };

  const getEventByIndex = (index) => {
    return index === -1 ? displayedTimeline : displayedTimeline?.events[index];
  };

  const generateUrlForAudioEvent = (id) => {
    return (
      "/audio/" +
      minimizeTitle(displayedTimeline?.title) +
      "-" +
      displayedTimeline?._id +
      "/" +
      id +
      ".mp3"
    );
  };

  const onReadWord = (charIndex, charLength) => {
    if (!isPlaying) return;
    const currentIndex = currentAudioIndexRef.current;
    const playingEvent = getEventByIndex(currentIndex);
    setPlayingAudioProgress({
      id: playingEvent._id,
      charIndex,
      charLength,
    });
  };

  const minimizeTitle = (input) => {
    if (!input) return input;
    let output = input.replace(" ", "-").trim().toLowerCase();
    return output;
  };

  const timelineString = timelineToString(
    displayedTimeline?._id ? displayedTimeline : EMPTY_TIMELINE_STRING
  );

  // This happens when no timeline is returned and we set null instead of undefined
  if (displayedTimeline === null)
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );

  return (
    <div className="timeline-page">
      <TimelineInstance
        className={"main-timeline"}
        dynamicHeight={true}
        data={timelineString}
        height={"500px"}
        onEventClick={_setClickedEvent}
      />

      {displayedTimeline?.hasAudio && (
        <div className="game-fixed-layer">
          <PlayerControls
            isPlaying={isPlaying}
            onStart={() => {
              setIsPlaying(true);
              audioRef.current.play();
            }}
            onPause={() => {
              setIsPlaying(false);
              audioRef.current.pause();
            }}
            onNext={playNext}
            onPrevious={playPrevious}
            isPrevDisabled={currentAudioIndexRef.current === -1}
            isNextDisabled={
              currentAudioIndexRef.current ===
              displayedTimeline?.events?.length - 1
            }
          />
          <AudioPointer
            isPlaying={isPlaying}
            text={getEventByIndex(currentAudioIndexRef.current).description}
            onReadWord={onReadWord}
            reset={synthReset}
          />
        </div>
      )}

      <div className="timeline-content">
        <div className="left-lane">
          <LeftSidebar timeline={displayedTimeline} />
        </div>
        <div className="center-lane">
          <FullDescription
            timeline={displayedTimeline}
            selectedEvent={clickedEvent}
            onPlayAudio={
              displayedTimeline?.hasAudio ? onPlayAudioForEvent : undefined
            }
            audioPlayProgress={playingAudioProgress}
          />
        </div>
        <div className="right-lane">
          <TimelinesList timelines={rightLaneTimelines} />
        </div>
      </div>
    </div>
  );
}

export default Timeline;
