import { PARAMETERS } from "../constants/parameters";

export class LogService {
  static displayLogs: string;
  static initialized = false;

  static init = () => {
    if (LogService.initialized) return;

    let url = new URL(window.location.href);
    LogService.displayLogs = url.searchParams.get(PARAMETERS.LOGGING) || "";
    LogService.initialized = true;
  };

  static log(...args: any[]): void {
    LogService.init();
    if (LogService.displayLogs) {
      console.log(...args);
    }
  }
}
