export const timelineToString = (timeline) => {
  if (!timeline) {
    return "";
  }

  const newTimeline = {
    id: timeline._id,
    title: timeline.title,
    initial_zoom: timeline.initialZoom || 20,
    max_zoom: timeline.maxZoom || 50,
    min_zoom: timeline.minZoom || 15,
    image_lane_height: timeline.imageLaneHeight || 100,
    focus_date: timeline.focusDate,
    display_zoom_level: false,
    backgroundColor: "#ccc",
  };

  if (timeline.events) {
    newTimeline.events = timeline.events.map((event) =>
      eventToTimelineModel(event)
    );
  }

  return "[" + JSON.stringify(newTimeline) + "]";
};

export const eventToTimelineModel = (event) => {
  return {
    id: event._id,
    title: event.title,
    startdate: event.startDate,
    enddate: event.endDate,
    importance: event.importance || 40,
    span_color: event.spanColor,
    image: event.image,
    icon: event.icon,
    low_threshold: event.lowThreshold,
    high_threshold: event.highThreshold,
  };
};
