import "./TimelineWidget.css";
import React from "react";
import importScript from "../../utils/ImportScript";
import importLink from "../../utils/ImportLink";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { LogService } from "../../utils/logService";
import { MouseInteraction } from "../mouseInteraction/MouseInteraction";
import { TimelinePlaceholder } from "../timelinePlaceholder/TimelinePlaceholder";
import { ROUTES } from "../../routes/routes-const";

let initialized = false;

const initialize = () => {
  importLink("./jquery-ui-1.10.3.custom.css");
  importLink("./Timeglider.css");

  importScript("./timeglider-dependencies.js");
  importScript("./timeglider-bundle-min.js");
};

const TimelineWidget = () => {
  const show = useSelector((state: RootState) => state.timeglider.visible);
  const data = useSelector((state: RootState) => state.timeglider.data);
  const height = useSelector((state: RootState) => state.timeglider.height);
  const style = useSelector((state: RootState) => state.timeglider.style);

  const documentLoad = () => {
    LogService.log("Event fired");
    initialized = true;
    window.loadTimeline();
    window.removeEventListener("documentLoaded", documentLoad);
  };

  initialize();
  if (!initialized) {
    window.addEventListener("documentLoaded", documentLoad);
  }

  useEffect(() => {
    LogService.log("LoadTimeline", window.loadTimelineData);
    window.loadTimelineData(data);
  }, [data]);

  useEffect(() => {
    if (window.refreshTimeline) {
      window.refreshTimeline();
    }

    if (window.resizeTimeline) {
      window.resizeTimeline();
    }
  }, [height, show]);

  const url = new URL(window.location.href);
  const isForceShow =
    url.pathname === ROUTES.TIMELINE || url.pathname === ROUTES.GUESSER;
    
  const localStyle =
    show || isForceShow
      ? { width: "100%", height: height || "500px" }
      : { width: "100%", display: "none", height: height || "500px" };
  return (
    <div className="timeline" style={localStyle}>
      <TimelinePlaceholder />
      <div
        id={`placement`}
        style={{ ...{ height: height, width: "100%" }, ...style }}
      ></div>
      {/* <MouseInteraction /> */}
    </div>
  );
};

export default TimelineWidget;
